<template>
    <div>
        <div v-bind:key="item.id" v-for="item in items">
            <trigger-item
             v-bind:item="item"
             v-on:del-item="deleteItem"/>
        </div>
        <button>
            Add trigger
        </button>
    </div>
</template>

<script>
import TriggerItem from '../components/TriggerItem.vue';

export default {
    name: "TriggersView",
    components: {
        TriggerItem
    },
    data() {
        return {
            items: [
                { id: 1, name: "Coffee" },
                { id: 2, name: "Alcohol" },
                { id: 3, name: "Medication" },
                { id: 4, name: "Food" },
                { id: 5, name: "Weather" },
                { id: 6, name: "Allergies" },
                { id: 7, name: "Bright light" },
                { id: 8, name: "Stress" },
                { id: 9, name: "Anxiety" },
                { id: 10, name: "Reading" },
                { id: 11, name: "Noise" },
                { id: 12, name: "Motion" },
                { id: 13, name: "Eye strain" },
                { id: 14, name: "PC/TV screen" },
                { id: 15, name: "Hunger" },
                { id: 16, name: "Insomnia" },
                { id: 17, name: "Smell" },
                { id: 18, name: "Sickness" },
                { id: 19, name: "Commute" },
                { id: 20, name: "PMS" }
            ]
        }
    },
    methods: {
        deleteItem(id) {
        this.items = this.items.filter(todo => todo.id !== id);
        },
        addItem(newItem) {
            this.items.unshift(newItem);
        }
    }
}

</script>

<style scoped>

</style>
