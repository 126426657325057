import Vue from 'vue'
import VueRouter from 'vue-router'

import HeadacheListView from '../views/HeadacheListView';
import StatisticsView from '../views/StatisticsView';
import TriggersView from '../views/TriggersView';

Vue.use(VueRouter)

const routes = [
  {
    path: '/headacheList',
    name: 'headacheList',
    component: HeadacheListView
  },
  {
    path: '/statistics',
    name: 'statistics',
    component: StatisticsView
  },
  {
    path: '/triggers',
    name: 'triggers',
    component: TriggersView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
