<template>
    <div class="headache-list-view">
        <div v-bind:key="item.id" v-for="item in items">
            <headache-item
             v-bind:item="item"
             v-on:del-item="deleteItem"/>
        </div>
    </div>
</template>

<script>
import HeadacheItem from '../components/HeadacheItem.vue';

export default {
    name: "HeadacheLog",
    components: {
        HeadacheItem
     },
     data() {
        return {
            items: [
                {
                id: 1,
                beginDate: "19-06-2022_18:30",
                endDate: "19-06-2022_19:30",
                location: LocationEnum.MIGRAINE,
                severity: 1,
                triggers: "stress",
                reliefMeasure: "sleep",
                notes:""
                },
                {
                id: 2,
                beginDate: "19-06-2022_18:30",
                endDate: "19-06-2022_19:30",
                location: LocationEnum.NECK,
                severity: 1,
                triggers: "stress",
                reliefMeasure: "sleep",
                notes:""
                },
                {
                id: 3,
                beginDate: "19-06-2022_18:30",
                endDate: "19-06-2022_19:30",
                location: LocationEnum.TENSION,
                severity: 1,
                triggers: "stress",
                reliefMeasure: "sleep",
                notes:""
                }
            ]
        }
    },
    methods: {
        deleteItem(id) {
        this.items = this.items.filter(todo => todo.id !== id);
        },
        addItem(newItem) {
            this.items.unshift(newItem);
        }
    }
}

const LocationEnum = Object.freeze({
  TENSION: "Tension",
  NECK: "Neck",
  MIGRAINE: "Migraine",
  CLUSTER: "Cluster",
  CGA: "CGA",
  SINUS: "Sinus"
});

</script>

<style scoped>

</style>
