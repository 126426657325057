<template>
    <div class="trigger-item">
        <p>
            {{item.name}}
            <button
             class="del"
             @click="$emit('del-item', item.id)"
             >x</button>
        </p> 
    </div>
</template>

<script>

export default {
    name: "TriggerItem",
    props: ["item"],
}

</script>

<style scoped>

.trigger-item {
    background: #f4f4f4;
    padding: 5px;
    border-bottom: 1px #ccc dotted;
}

.del {
    background: #ff0000;
    color: #fff;
    border: none;
    padding: 5px 9px;
    border-radius: 50%;
    cursor: pointer;
    float: right;
}

</style>
